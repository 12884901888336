import React, { useState } from "react";

const SelectComponent = ({ handleChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isCatOpen, setIsCatOpen] = useState(false);
  const [isCatClose, setIsCatClose] = useState(false);

  // Array de categorias e subcategorias
  const options = [
    {
      category: "GERAL E INESPECÍFICO",
      subcategories: [
        {
          value: "A01",
          label: "Dor generalizada /múltipla"
        },
        {
          value: "A02",
          label: "Arrepios/ calafrios"
        },
        {
          value: "A03",
          label: "Febre"
        },
        {
          value: "A04",
          label: "Debilidade/cansaço geral/fadiga"
        },
        {
          value: "A05",
          label: "Sentir-se doente"
        },
        {
          value: "A06",
          label: "Desmaio/síncope"
        },
        {
          value: "A07",
          label: "Coma"
        },
        {
          value: "A08",
          label: "Inchaço"
        },
        {
          value: "A09",
          label: "Problemas de sudorese"
        },
        {
          value: "A10",
          label: "Sangramento/Hemorragia NE"
        },
        {
          value: "A11",
          label: "Dores torácicas NE"
        },
        {
          value: "A13",
          label: "Receio/Medo do tratamento"
        },
        {
          value: "A16",
          label: "Criança irritável"
        },
        {
          value: "A18",
          label: "Preocupação com aparência"
        },
        {
          value: "A20",
          label: "Pedido/discussão eutanásia"
        },
        {
          value: "A21",
          label: "Fator de risco de malignidade"
        },
        {
          value: "A23",
          label: "Fator de risco NE"
        },
        {
          value: "A25",
          label: "Medo de morrer/medo da morte"
        },
        {
          value: "A26",
          label: "Medo de câncer NE"
        },
        {
          value: "A27",
          label: "Medo de outra doença NE"
        },
        {
          value: "A28",
          label: "Limitação funcional/incapacidade NE"
        },
        {
          value: "A29",
          label: "Outros sinais/sintomas gerais"
        },
        {
          value: "A70",
          label: "Tuberculose"
        },
        {
          value: "A71",
          label: "Sarampo"
        },
        {
          value: "A72",
          label: "Varicela"
        },
        {
          value: "A73",
          label: "Malária"
        },
        {
          value: "A74",
          label: "Rubéola"
        },
        {
          value: "A75",
          label: "Mononucleose infecciosa"
        },
        {
          value: "A76",
          label: "Outro exantema viral"
        },
        {
          value: "A77",
          label: "Dengue e outras doenças virais NE"
        },
        {
          value: "A78",
          label: "Hanseníase e outras doenças infecciosas NE"
        },
        {
          value: "A79",
          label: "Carcinomatose (localização primária desconhecida)"
        },
        {
          value: "A80",
          label: "Lesão traumática/acidente NE"
        },
        {
          value: "A81",
          label: "Politraumatismos/ferimentos múltiplos"
        },
        {
          value: "A82",
          label: "Efeito secundário de lesão traumática"
        },
        {
          value: "A84",
          label: "Intoxicação por medicamento"
        },
        {
          value: "A85",
          label: "Efeito adverso de fármaco dose correta"
        },
        {
          value: "A86",
          label: "Efeito tóxico de substância não medicinal"
        },
        {
          value: "A87",
          label: "Complicações de tratamento médico"
        },
        {
          value: "A88",
          label: "Efeito adverso de fator físico"
        },
        {
          value: "A89",
          label: "Efeito da prótese"
        },
        {
          value: "A90",
          label: "Malformação congênita NE/múltiplas"
        },
        {
          value: "A91",
          label: "Investigação com resultado anormal NE"
        },
        {
          value: "A92",
          label: "Alergia/reação alérgica NE"
        },
        {
          value: "A93",
          label: "Recém-nascido prematuro"
        },
        {
          value: "A94",
          label: "Morbidade perinatal, outra"
        },
        {
          value: "A95",
          label: "Mortalidade perinatal"
        },
        {
          value: "A96",
          label: "Morte"
        },
        {
          value: "A97",
          label: "Sem doença"
        },
        {
          value: "A98",
          label: "Medicina preventiva/manutenção da saúde"
        },
        {
          value: "A99",
          label: "Outras doenças gerais NE"
        }
      ],
    },
    {
      category: "SANGUE, SISTEMA HEMATOPOIÉTICO, LINFÁTICO E BAÇO",
      subcategories:[
        {
          value: "B02",
          label: "Gânglio linfático aumentado/doloroso"
        },
        {
          value: "B04",
          label: "Sinais/sintomas sangue"
        },
        {
          value: "B25",
          label: "Medo de VIH/ HIV/SIDA/ AIDS"
        },
        {
          value: "B26",
          label: "Medo de câncer no sangue/linfático"
        },
        {
          value: "B27",
          label: "Medo de outras doenças do sangue/vasos linfáticos"
        },
        {
          value: "B28",
          label: "Limitação funcional/incapacidade"
        },
        {
          value: "B29",
          label: "Outros sinais/ sintomas do sangue/sistema linfático/baço NE"
        },
        {
          value: "B70",
          label: "Linfadenite aguda"
        },
        {
          value: "B71",
          label: "Linfadenite crônica NE"
        },
        {
          value: "B72",
          label: "Doença de Hodgkin/linfomas"
        },
        {
          value: "B73",
          label: "Leucemia"
        },
        {
          value: "B74",
          label: "Outra neoplasia maligna no sangue"
        },
        {
          value: "B75",
          label: "Neoplasia benigna NE"
        },
        {
          value: "B76",
          label: "Rotura traumática do baço"
        },
        {
          value: "B77",
          label: "Outras lesões traumáticas do sangue/linfa/baço"
        },
        {
          value: "B78",
          label: "Anemia hemolítica hereditária"
        },
        {
          value: "B79",
          label: "Outra malformação congênita do sangue/linfática"
        },
        {
          value: "B82",
          label: "Outras anemias NE"
        },
        {
          value: "B83",
          label: "Púrpura/defeitos de coagulação"
        },
        {
          value: "B84",
          label: "Glóbulos brancos anormais"
        },
        {
          value: "B87",
          label: "Esplenomegalia"
        },
        {
          value: "B90",
          label: "Infecção por VIH/ HIV/SIDA/ AIDS"
        },
        {
          value: "B99",
          label: "Outra doença do sangue/linfáticos/baço"
        }
      ]      
    },
    {
      category: "SISTEMA DIGESTIVO",
      subcategories: [
        {
          value: "D01",
          label: "Dor abdominal generalizada/cólicas"
        },
        {
          value: "D02",
          label: "Dores abdominais, epigástricas"
        },
        {
          value: "D03",
          label: "Azia/ Queimação"
        },
        {
          value: "D04",
          label: "Dor anal/retal"
        },
        {
          value: "D05",
          label: "Irritação perianal"
        },
        {
          value: "D06",
          label: "Outras dores abdominais localizadas"
        },
        {
          value: "D07",
          label: "Dispepsia/indigestão"
        },
        {
          value: "D08",
          label: "Flatulência /gases/eructações"
        },
        {
          value: "D09",
          label: "Náusea"
        },
        {
          value: "D10",
          label: "Vomito"
        },
        {
          value: "D11",
          label: "Diarreia"
        },
        {
          value: "D12",
          label: "Obstipação"
        },
        {
          value: "D13",
          label: "Icterícia"
        },
        {
          value: "D14",
          label: "Hematêmese/vômito sangue"
        },
        {
          value: "D15",
          label: "Melena"
        },
        {
          value: "D16",
          label: "Hemorragia retal"
        },
        {
          value: "D17",
          label: "Incontinência fecal"
        },
        {
          value: "D18",
          label: "Alterações nas fezes/mov. intestinais"
        },
        {
          value: "D19",
          label: "Sinais/sintomas dos dentes/gengivas"
        },
        {
          value: "D20",
          label: "Sinais/sintomas da boca/língua/lábios"
        },
        {
          value: "D21",
          label: "Problemas de deglutição"
        },
        {
          value: "D23",
          label: "Hepatomegalia"
        },
        {
          value: "D24",
          label: "Massa abdominal NE"
        },
        {
          value: "D25",
          label: "Distensão abdominal"
        },
        {
          value: "D26",
          label: "Medo de câncer no aparelho digestivo"
        },
        {
          value: "D27",
          label: "Medo de outras doenças aparelho digestivo"
        },
        {
          value: "D28",
          label: "Limitação funcional/incapacidade"
        },
        {
          value: "D29",
          label: "Outros sinais/sintomas digestivos"
        },
        {
          value: "D70",
          label: "Infecção gastrointestinal"
        },
        {
          value: "D71",
          label: "Caxumba/parotidite epidêmica"
        },
        {
          value: "D72",
          label: "Hepatite viral"
        },
        {
          value: "D73",
          label: "Gastroenterite, presumível infecção"
        },
        {
          value: "D74",
          label: "Neoplasia maligna do estômago"
        },
        {
          value: "D75",
          label: "Neoplasia maligna do cólon/reto"
        },
        {
          value: "D76",
          label: "Neoplasia maligna do pâncreas"
        },
        {
          value: "D77",
          label: "Neoplasia maligna do aparelho digestivo NE"
        },
        {
          value: "D78",
          label: "Neoplasia benigna do aparelho digestivo/incerta"
        },
        {
          value: "D79",
          label: "Corpo estranho no aparelho digestivo"
        },
        {
          value: "D80",
          label: "Outras lesões traumáticas"
        },
        {
          value: "D81",
          label: "Malformações congênitasdo aparelho digestivo"
        },
        {
          value: "D82",
          label: "Doença dos dentes/gengivas"
        },
        {
          value: "D83",
          label: "Doença da boca/língua/lábios"
        },
        {
          value: "D84",
          label: "Doença do esôfago"
        },
        {
          value: "D85",
          label: "Úlcera do duodeno"
        },
        {
          value: "D86",
          label: "Úlcera péptica, outra"
        },
        {
          value: "D87",
          label: "Alterações funcionais estômago"
        },
        {
          value: "D88",
          label: "Apendicite"
        },
        {
          value: "D89",
          label: "Hérnia inguinal"
        },
        {
          value: "D90",
          label: "Hérnia de hiato /diafragmática"
        },
        {
          value: "D91",
          label: "Hérnia abdominal, outras"
        },
        {
          value: "D92",
          label: "Doença diverticular intestinal"
        },
        {
          value: "D93",
          label: "Síndrome do cólon irritável"
        },
        {
          value: "D94",
          label: "Enterite crônica / colite ulcerosa"
        },
        {
          value: "D95",
          label: "Fissura anal / abcesso perianal"
        },
        {
          value: "D96",
          label: "Lombrigas /outros parasitas"
        },
        {
          value: "D97",
          label: "Doenças do fígado /NE"
        },
        {
          value: "D98",
          label: "Colecistite, colelitíase"
        },
        {
          value: "D99",
          label: "Outra doença do aparelho digestivo"
        }
      ]      
    },
    {
      category: "OLHO",
      subcategories: [
        {
          value: "F01",
          label: "Dor no olho"
        },
        {
          value: "F02",
          label: "Olho vermelho"
        },
        {
          value: "F03",
          label: "Secreção ocular"
        },
        {
          value: "F04",
          label: "Moscas volantes/pontos luminosos/escotomas/manchas"
        },
        {
          value: "F05",
          label: "Outras perturbações visuais"
        },
        {
          value: "F13",
          label: "Sensações oculares anormais"
        },
        {
          value: "F14",
          label: "Movimentos oculares anormais"
        },
        {
          value: "F15",
          label: "Aparência anormal nos olhos"
        },
        {
          value: "F16",
          label: "Sinais/sintomas das pálpebras"
        },
        {
          value: "F17",
          label: "Sinais/sintomas relacionados a óculos"
        },
        {
          value: "F18",
          label: "Sinais/sintomas relacionados a lentes de contato"
        },
        {
          value: "F27",
          label: "Medo de doença ocular"
        },
        {
          value: "F28",
          label: "Limitação funcional/incapacidade"
        },
        {
          value: "F29",
          label: "Outros sinais/sintomas oculares"
        },
        {
          value: "F70",
          label: "Conjuntivite infecciosa"
        },
        {
          value: "F71",
          label: "Conjuntivite alérgica"
        },
        {
          value: "F72",
          label: "Blefarite/hordéolo/calázio"
        },
        {
          value: "F73",
          label: "Outras infecções/inflamações oculares"
        },
        {
          value: "F74",
          label: "Neoplasia do olho/anexos"
        },
        {
          value: "F75",
          label: "Contusão/hemorragia ocular"
        },
        {
          value: "F76",
          label: "Corpo estranho ocular"
        },
        {
          value: "F79",
          label: "Outras lesões traumáticas oculares"
        },
        {
          value: "F80",
          label: "Obstrução canal lacrimal da criança"
        },
        {
          value: "F81",
          label: "Outras malformações congênitas do olho"
        },
        {
          value: "F82",
          label: "Descolamento da retina"
        },
        {
          value: "F83",
          label: "Retinopatia"
        },
        {
          value: "F84",
          label: "Degeneração macular"
        },
        {
          value: "F85",
          label: "Úlcera da córnea"
        },
        {
          value: "F86",
          label: "Tracoma"
        },
        {
          value: "F91",
          label: "Erro de refração"
        },
        {
          value: "F92",
          label: "Catarata"
        },
        {
          value: "F93",
          label: "Glaucoma"
        },
        {
          value: "F94",
          label: "Cegueira"
        },
        {
          value: "F95",
          label: "Estrabismo"
        },
        {
          value: "F99",
          label: "Outra doenças oculares/anexos"
        }
      ]      
    },
    {
      category: "OUVIDO",
      subcategories:[
        {
          value: "H01",
          label: "Dor de ouvidos"
        },
        {
          value: "H02",
          label: "Problemas de audição"
        },
        {
          value: "H03",
          label: "Acufeno, zumbidos, ruído, assobios"
        },
        {
          value: "H04",
          label: "Secreção no ouvido"
        },
        {
          value: "H05",
          label: "Hemorragia no ouvido"
        },
        {
          value: "H13",
          label: "Sensação de ouvido tapado"
        },
        {
          value: "H15",
          label: "Preocupação com a aparência das orelhas"
        },
        {
          value: "H27",
          label: "Medo de doença do ouvido"
        },
        {
          value: "H28",
          label: "Limitação funcional/incapacidade"
        },
        {
          value: "H29",
          label: "Outros sinais/sintomas ouvido"
        },
        {
          value: "H70",
          label: "Otite externa"
        },
        {
          value: "H71",
          label: "Otite media aguda/miringite"
        },
        {
          value: "H72",
          label: "Otite média serosa"
        },
        {
          value: "H73",
          label: "Infecção da Trompa de Eustáquio"
        },
        {
          value: "H74",
          label: "Otite media crônica"
        },
        {
          value: "H75",
          label: "Neoplasia do ouvido"
        },
        {
          value: "H76",
          label: "Corpo estranho do ouvido"
        },
        {
          value: "H77",
          label: "Perfuração do tímpano"
        },
        {
          value: "H78",
          label: "Fibrilação/utter auricular/atrial"
        },
        {
          value: "H79",
          label: "Outros traumatismos do ouvido"
        },
        {
          value: "H80",
          label: "Malformações congênitas do ouvido"
        },
        {
          value: "H81",
          label: "Cerúmen no ouvido em excesso"
        },
        {
          value: "H82",
          label: "Síndrome vertiginosa"
        },
        {
          value: "H83",
          label: "Otoesclerose"
        },
        {
          value: "H84",
          label: "Presbiacusia"
        },
        {
          value: "H85",
          label: "Lesão acústica"
        },
        {
          value: "H86",
          label: "Surdez"
        },
        {
          value: "H99",
          label: "Outra doença do ouvido/mastóide"
        }
      ]      
    },
    {
      category: "SISTEMA CIRCULATÓRIO",
      subcategories: [
        {
          value: "K01",
          label: "Dor atribuída ao coração"
        },
        {
          value: "K02",
          label: "Sensação de pressão/aperto atribuída ao coração"
        },
        {
          value: "K03",
          label: "Dores atribuídas ao aparelho circulatório NE"
        },
        {
          value: "K04",
          label: "Palpitações/percepção dos batimentos cardíacos"
        },
        {
          value: "K05",
          label: "Outras irregularidades dos batimentos cardíacos"
        },
        {
          value: "K06",
          label: "Veias proeminentes"
        },
        {
          value: "K07",
          label: "Tornozelos inchados/edema"
        },
        {
          value: "K22",
          label: "Fator de risco para doença cardiovascular"
        },
        {
          value: "K24",
          label: "Medo de doença cardíaca"
        },
        {
          value: "K25",
          label: "Medo de hipertensão"
        },
        {
          value: "K27",
          label: "Medo de outra doença cardiovascular"
        },
        {
          value: "K28",
          label: "Limitação funcional/incapacidade"
        },
        {
          value: "K29",
          label: "Outros sinais/sintomas cardiovasculares"
        },
        {
          value: "K70",
          label: "Doença infecciosa do aparelho circulatório"
        },
        {
          value: "K71",
          label: "Febre reumática/cardiopatia"
        },
        {
          value: "K72",
          label: "Neoplasia do aparelho circulatório"
        },
        {
          value: "K73",
          label: "Malformações congênitas do aparelho circulatório"
        },
        {
          value: "K74",
          label: "Doença cardíaca isquêmica com angina"
        },
        {
          value: "K75",
          label: "Infarto ou Enfarte agudo miocárdio"
        },
        {
          value: "K76",
          label: "Doença cardíaca isquémica sem angina"
        },
        {
          value: "K79",
          label: "Taquicardia Paroxística Fibrilação/Flutter auricular/atrial Insuficiência cardíaca"
        },
        {
          value: "K80",
          label: "Arritmia cardíaca NE"
        },
        {
          value: "K81",
          label: "Sopro cardíaco/arterial NE"
        },
        {
          value: "K82",
          label: "Doença cardiopulmonar"
        },
        {
          value: "K83",
          label: "Doença valvular cardíaca NE"
        },
        {
          value: "K84",
          label: "Outras doenças cardíacas"
        },
        {
          value: "K85",
          label: "Pressão arterial elevada"
        },
        {
          value: "K86",
          label: "Hipertensão sem complicações"
        },
        {
          value: "K87",
          label: "Hipertensão com complicações"
        },
        {
          value: "K88",
          label: "Hipotensão postural"
        },
        {
          value: "K89",
          label: "Isquemia/acidente cerebral transitória(o)"
        },
        {
          value: "K90",
          label: "Trombose/acidente vascular cerebral"
        },
        {
          value: "K91",
          label: "Doença vascular cerebral"
        },
        {
          value: "K92",
          label: "Aterosclerose/doença vascular periférica"
        },
        {
          value: "K93",
          label: "Embolia pulmonar"
        },
        {
          value: "K94",
          label: "Flebite/tromboflebite"
        },
        {
          value: "K95",
          label: "Veias varicosas da perna"
        },
        {
          value: "K96",
          label: "Hemorróidas"
        },
        {
          value: "K99",
          label: "Outras doenças do aparelho circulatório"
        }
      ]      
    },
    {
      category: "MÚSCULO-ESQUELÉTICO",
      subcategories: [
        {
          "value": "L01",
          "label": "Sinais/sintomas do pescoço"
        },
        {
          "value": "L02",
          "label": "Sinais/sintomas da região dorsal"
        },
        {
          "value": "L03",
          "label": "Sinais/sintomas da região lombar"
        },
        {
          "value": "L04",
          "label": "Sinais/sintomas do tórax"
        },
        {
          "value": "L05",
          "label": "Sinais/sintomas da axila"
        },
        {
          "value": "L07",
          "label": "Sinais/sintomas da mandíbula"
        },
        {
          "value": "L08",
          "label": "Sinais/sintomas dos ombros"
        },
        {
          "value": "L09",
          "label": "Sinais/sintomas dos braços"
        },
        {
          "value": "L10",
          "label": "Sinais/sintomas dos cotovelos"
        },
        {
          "value": "L11",
          "label": "Sinais/sintomas dos punhos"
        },
        {
          "value": "L12",
          "label": "Sinais/sintomas das mãos e dedos"
        },
        {
          "value": "L13",
          "label": "Sinais/sintomas do quadril"
        },
        {
          "value": "L14",
          "label": "Sinais/sintomas da coxa/perna"
        },
        {
          "value": "L15",
          "label": "Sinais/sintomas do joelho"
        },
        {
          "value": "L16",
          "label": "Sinais/sintomas do tornozelo"
        },
        {
          "value": "L17",
          "label": "Sinais/sintomas do pé/dedos pé"
        },
        {
          "value": "L18",
          "label": "Dores musculares"
        },
        {
          "value": "L19",
          "label": "Sinais/sintomas musculares NE"
        },
        {
          "value": "L20",
          "label": "Sinais/sintomas das articulações NE"
        },
        {
          "value": "L26",
          "label": "Medo de câncer no aparelho músculoesquelético"
        },
        {
          "value": "L27",
          "label": "Medo de doença no aparelho músculoesquelético, outro"
        },
        {
          "value": "L28",
          "label": "Limitação funcional/incapacidade"
        },
        {
          "value": "L29",
          "label": "Outros sinais/sintomas do aparelho músculo-esquelético"
        },
        {
          "value": "L70",
          "label": "Infecções do aparelho músculo-esquelético"
        },
        {
          "value": "L71",
          "label": "Neoplasia maligna do aparelho músculoesquelético"
        },
        {
          "value": "L72",
          "label": "Fratura: rádio/cúbito"
        },
        {
          "value": "L73",
          "label": "Fratura: tíbia/perônio/ fíbula"
        },
        {
          "value": "L74",
          "label": "Fratura: osso da mão/pé"
        },
        {
          "value": "L75",
          "label": "Fratura: fêmur"
        },
        {
          "value": "L76",
          "label": "Outras fraturas"
        },
        {
          "value": "L77",
          "label": "Entorses e distensões do tornozelo"
        },
        {
          "value": "L78",
          "label": "Entorses e distensões do joelho"
        },
        {
          "value": "L79",
          "label": "Entorses e distensões das articulações NE"
        },
        {
          "value": "L80",
          "label": "Luxação/subluxação"
        },
        {
          "value": "L81",
          "label": "Traumatismos do aparelho musculoesquelético NE"
        },
        {
          "value": "L82",
          "label": "Malformações congênitas do aparelho músculo-esquelético"
        },
        {
          "value": "L83",
          "label": "Doenças ou síndromes da coluna cervical"
        },
        {
          "value": "L84",
          "label": "Doenças ou síndromes da coluna sem irradiação de dor"
        },
        {
          "value": "L85",
          "label": "Deformação adquirida da coluna"
        },
        {
          "value": "L86",
          "label": "Síndrome vertebral com irradiação dor"
        },
        {
          "value": "L87",
          "label": "Bursite/tendinite/sinovite NE"
        },
        {
          "value": "L88",
          "label": "Artrite reumatóide/soropositiva"
        },
        {
          "value": "L89",
          "label": "Osteoartrose do quadril"
        },
        {
          "value": "L90",
          "label": "Osteoartrose do joelho"
        },
        {
          "value": "L91",
          "label": "Outras osteoartroses"
        },
        {
          "value": "L92",
          "label": "Síndrome do ombro doloroso"
        },
        {
          "value": "L93",
          "label": "Cotovelo de tenista"
        },
        {
          "value": "L94",
          "label": "Osteocondrose"
        },
        {
          "value": "L95",
          "label": "Osteoporose"
        },
        {
          "value": "L96",
          "label": "Lesão interna aguda do joelho"
        },
        {
          "value": "L97",
          "label": "Neoplasia benigna/incertas"
        },
        {
          "value": "L98",
          "label": "Malformação adquirida de um membro"
        },
        {
          "value": "L99",
          "label": "Outra doença do aparelho músculo-esquelético"
        }
      ]      
    },
    {
      category: "NEUROLÓGICO",
      subcategories:[
        {
          "value": "N01",
          "label": "Cefaléia"
        },
        {
          "value": "N03",
          "label": "Dores da face"
        },
        {
          "value": "N04",
          "label": "Síndrome das pernas inquietas"
        },
        {
          "value": "N05",
          "label": "Formigamento/parestesia nos dedos das mãos/pés"
        },
        {
          "value": "N06",
          "label": "Outras alterações da sensibilidade"
        },
        {
          "value": "N07",
          "label": "Convulsões/ataques"
        },
        {
          "value": "N08",
          "label": "Movimentos involuntários anormais"
        },
        {
          "value": "N16",
          "label": "Alterações do olfato/gosto"
        },
        {
          "value": "N17",
          "label": "Vertigens/tonturas"
        },
        {
          "value": "N18",
          "label": "Paralisia/fraqueza"
        },
        {
          "value": "N19",
          "label": "Perturbações da fala"
        },
        {
          "value": "N26",
          "label": "Medo de câncer do sistema neurológico"
        },
        {
          "value": "N27",
          "label": "Medo de outras doenças neurológicas"
        },
        {
          "value": "N28",
          "label": "Limitação funcional/incapacidade"
        },
        {
          "value": "N29",
          "label": "Sinais/sintomas do sistema neurológico, outros"
        },
        {
          "value": "N70",
          "label": "Poliomielite"
        },
        {
          "value": "N71",
          "label": "Meningite/encefalite"
        },
        {
          "value": "N72",
          "label": "Tétano"
        },
        {
          "value": "N73",
          "label": "Outra infecção neurológica"
        },
        {
          "value": "N74",
          "label": "Neoplasia maligna do sistema neurológico"
        },
        {
          "value": "N75",
          "label": "Neoplasia benigna do sistema neurológico"
        },
        {
          "value": "N76",
          "label": "Neoplasia do sistema neurológico de natureza incerta"
        },
        {
          "value": "N79",
          "label": "Concussão"
        },
        {
          "value": "N80",
          "label": "Outras lesões cranianas"
        },
        {
          "value": "N81",
          "label": "Outra lesão do sistema neurológico"
        },
        {
          "value": "N85",
          "label": "Malformações congênitas"
        },
        {
          "value": "N86",
          "label": "Esclerose múltipla"
        },
        {
          "value": "N87",
          "label": "Parkinsonismo"
        },
        {
          "value": "N88",
          "label": "Epilepsia"
        },
        {
          "value": "N89",
          "label": "Enxaqueca"
        },
        {
          "value": "N90",
          "label": "Cefaléia de cluster"
        },
        {
          "value": "N91",
          "label": "Paralisia facial/paralisia de Bell"
        },
        {
          "value": "N92",
          "label": "Nevralgia do trigêmeo"
        },
        {
          "value": "N93",
          "label": "Síndrome do túnel do carpo/Síndrome do canal cárpico"
        },
        {
          "value": "N94",
          "label": "Neurite/Nevrite/neuropatia periférica"
        },
        {
          "value": "N95",
          "label": "Cefaléia tensional"
        },
        {
          "value": "N99",
          "label": "Outras doenças do sistema neurológico"
        }
      ]      
    },
    {
      category: "PSICOLÓGICO",
      subcategories: [
        {
          value: "P01",
          label: "Sensação de ansiedade/nervosismo/tensão",
        },
        {
          value: "P02",
          label: "Reação aguda ao estresse",
        },
        {
          value: "P03",
          label: "Tristeza/ Sensação de depressão",
        },
        {
          value: "P04",
          label: "Sentir/comportar-se de forma irritável/zangada",
        },
        {
          value: "P05",
          label: "Sensação/comportamento senil",
        },
        {
          value: "P06",
          label: "Perturbação do sono",
        },
        {
          value: "P07",
          label: "Diminuição do desejo sexual",
        },
        {
          value: "P08",
          label: "Diminuição da satisfação sexual",
        },
        {
          value: "P09",
          label: "Preocupação com a preferência sexual",
        },
        {
          value: "P10",
          label: "Gaguejar/balbuciar/tiques",
        },
        {
          value: "P11",
          label: "Problemas de alimentação da criança",
        },
        {
          value: "P12",
          label: "Molhar a cama/enurese",
        },
        {
          value: "P13",
          label: "Encoprese/outros problemas de incontinência fecal",
        },
        {
          value: "P15",
          label: "Abuso crônico de álcool",
        },
        {
          value: "P16",
          label: "Abuso agudo de álcool",
        },
        {
          value: "P17",
          label: "Abuso do tabaco",
        },
        {
          value: "P18",
          label: "Abuso de medicação",
        },
        {
          value: "P19",
          label: "Abuso de drogas",
        },
        {
          value: "P20",
          label: "Alterações da memória",
        },
        {
          value: "P22",
          label: "Sinais/sintomas relacionados ao comportamento da criança",
        },
        {
          value: "P23",
          label: "Sinais/sintomas relacionados ao comportamento do adolescente",
        },
        {
          value: "P25",
          label: "Problemas da fase de vida de adulto",
        },
        {
          value: "P27",
          label: "Medo de perturbações mentais",
        },
        {
          value: "P28",
          label: "Limitação funcional/incapacidade",
        },
        {
          value: "P29",
          label: "Sinais/sintomas psicológicos, outros",
        },
        {
          value: "P70",
          label: "Demência",
        },
        {
          value: "P71",
          label: "Outras psicoses orgânicas NE",
        },
        {
          value: "P72",
          label: "Esquizofrenia",
        },
        {
          value: "P73",
          label: "Psicose afetiva",
        },
        {
          value: "P74",
          label: "Distúrbio ansioso/estado de ansiedade",
        },
        {
          value: "P75",
          label: "Somatização",
        },
        {
          value: "P76",
          label: "Perturbações depressivas",
        },
        {
          value: "P77",
          label: "Suicídio/tentativa de suicídio",
        },
        {
          value: "P78",
          label: "Neurastenia",
        },
        {
          value: "P79",
          label: "Fobia/perturbação compulsiva",
        },
        {
          value: "P80",
          label: "Perturbações de personalidade",
        },
        {
          value: "P81",
          label: "Perturbação hipercinética",
        },
        {
          value: "P82",
          label: "Estresse pós-traumático",
        },
        {
          value: "P85",
          label: "Retardo/ Atraso mental",
        },
        {
          value: "P86",
          label: "Anorexia nervosa, bulimia",
        },
        {
          value: "P98",
          label: "Outras psicoses NE",
        },
        {
          value: "P99",
          label: "Outras perturbações psicológicas",
        },
      ],
    },
    {
      category: "RESPIRATÓRIO",
      subcategories: [
        {
          value: "R01",
          label: "Dor atribuída ao aparelho respiratório",
        },
        {
          value: "R02",
          label: "Dificuldade respiratória, dispneia",
        },
        {
          value: "R03",
          label: "Respiração ruidosa",
        },
        {
          value: "R04",
          label: "Outros problemas respiratórios",
        },
        {
          value: "R05",
          label: "Tosse",
        },
        {
          value: "R06",
          label: "Hemorragia nasal/epistaxe",
        },
        {
          value: "R07",
          label: "Espirro/congestão nasal",
        },
        {
          value: "R08",
          label: "Outros sinais/sintomas nasais",
        },
        {
          value: "R09",
          label: "Sinais/sintomas dos seios paranasais",
        },
        {
          value: "R21",
          label: "Sinais/sintomas da garganta",
        },
        {
          value: "R23",
          label: "Sinais/sintomas da voz",
        },
        {
          value: "R24",
          label: "Hemoptise",
        },
        {
          value: "R25",
          label: "Expectoração/mucosidade anormal",
        },
        {
          value: "R26",
          label: "Medo de câncer do aparelho respiratório",
        },
        {
          value: "R27",
          label: "Medo de outras doenças respiratórias",
        },
        {
          value: "R28",
          label: "Limitação funcional/incapacidade",
        },
        {
          value: "R29",
          label: "Sinais/sintomas do aparelho respiratório, outros",
        },
        {
          value: "R71",
          label: "Tosse convulsa/pertussis",
        },
        {
          value: "R72",
          label: "Infecção estreptocócica da orofaringe",
        },
        {
          value: "R73",
          label: "Abscesso/furúnculo no nariz",
        },
        {
          value: "R74",
          label: "Infecção aguda do aparelho respiratório superior (IVAS)",
        },
        {
          value: "R75",
          label: "Sinusite crônica/aguda",
        },
        {
          value: "R76",
          label: "Amigdalite aguda",
        },
        {
          value: "R77",
          label: "Laringite/traqueíte aguda",
        },
        {
          value: "R78",
          label: "Bronquite/bronquiolite aguda",
        },
        {
          value: "R79",
          label: "Bronquite crônica",
        },
        {
          value: "R80",
          label: "Gripe",
        },
        {
          value: "R81",
          label: "Pneumonia",
        },
        {
          value: "R82",
          label: "Pleurite/derrame pleural",
        },
        {
          value: "R83",
          label: "Outra infecção respiratória",
        },
        {
          value: "R84",
          label: "Neoplasia maligna dos brônquios/pulmão",
        },
        {
          value: "R85",
          label: "Outra neoplasia respiratória maligna",
        },
        {
          value: "R86",
          label: "Neoplasia benigna respiratória",
        },
        {
          value: "R87",
          label: "Corpo estranho nariz/laringe/brônquios",
        },
        {
          value: "R88",
          label: "Outra lesão respiratória",
        },
        {
          value: "R89",
          label: "Malformação congênita do aparelho respiratório",
        },
        {
          value: "R90",
          label: "Hipertrofia das amígdalas/adenóides",
        },
        {
          value: "R92",
          label: "Neoplasia respiratória NE",
        },
        {
          value: "R95",
          label: "Doença pulmonar obstrutiva crônica",
        },
        {
          value: "R96",
          label: "Asma",
        },
        {
          value: "R97",
          label: "Rinite alérgica",
        },
        {
          value: "R98",
          label: "Síndrome de hiperventilação",
        },
        {
          value: "R99",
          label: "Outras doenças respiratórias",
        },
      ],
    },
    {
      category: "PELE",
      subcategories: [
        {
          value: "S01",
          label: "Dor/sensibilidade dolorosa da pele",
        },
        {
          value: "S02",
          label: "Prurido",
        },
        {
          value: "S03",
          label: "Verrugas",
        },
        {
          value: "S04",
          label: "Tumor/inchaço localizado",
        },
        {
          value: "S05",
          label: "Tumores/inchaços generalizados",
        },
        {
          value: "S06",
          label: "Erupção cutânea localizada",
        },
        {
          value: "S07",
          label: "Erupção cutânea generalizada",
        },
        {
          value: "S08",
          label: "Alterações da cor da pele",
        },
        {
          value: "S09",
          label: "Infecção dos dedos das mãos/pés",
        },
        {
          value: "S10",
          label: "Furúnculo/carbúnculo",
        },
        {
          value: "S11",
          label: "Infecção pós-traumática da pele",
        },
        {
          value: "S12",
          label: "Picada ou mordedura de inseto",
        },
        {
          value: "S13",
          label: "Mordedura animal/humana",
        },
        {
          value: "S14",
          label: "Queimadura/escaldão",
        },
        {
          value: "S15",
          label: "Corpo estranho na pele",
        },
        {
          value: "S16",
          label: "Traumatismo/contusão",
        },
        {
          value: "S17",
          label: "Abrasão/arranhão/bolhas",
        },
        {
          value: "S18",
          label: "Laceração/corte",
        },
        {
          value: "S19",
          label: "Outra lesão cutânea",
        },
        {
          value: "S20",
          label: "Calos/calosidades",
        },
        {
          value: "S21",
          label: "Sinais/sintomas da textura da pele",
        },
        {
          value: "S22",
          label: "Sinais/sintomas das unhas",
        },
        {
          value: "S23",
          label: "Queda de cabelo/calvície",
        },
        {
          value: "S24",
          label: "Sinais/sintomas do cabelo/couro cabeludo",
        },
        {
          value: "S26",
          label: "Medo de câncer de pele",
        },
        {
          value: "S27",
          label: "Medo de outra doença da pele",
        },
        {
          value: "S28",
          label: "Limitação funcional/incapacidade",
        },
        {
          value: "S29",
          label: "Sinais/sintomas da pele, outros",
        },
        {
          value: "S70",
          label: "Herpes zoster",
        },
        {
          value: "S71",
          label: "Herpes simples",
        },
        {
          value: "S72",
          label: "Escabiose/outras acaríases",
        },
        {
          value: "S73",
          label: "Pediculose/outras infecções da pele",
        },
        {
          value: "S75",
          label: "Monilíase oral/candidíase na pele",
        },
        {
          value: "S76",
          label: "Outras infecções da pele",
        },
        {
          value: "S77",
          label: "Neoplasias malignas da pele",
        },
        {
          value: "S78",
          label: "Lipoma",
        },
        {
          value: "S79",
          label: "Neoplasia cutânea benigna/incerta",
        },
        {
          value: "S80",
          label: "Ceratose/Queratose solar/queimadura solar",
        },
        {
          value: "S81",
          label: "Hemangioma/linfangioma",
        },
        {
          value: "S82",
          label: "Nevos/sinais da pele",
        },
        {
          value: "S83",
          label: "Lesões da pele congênitas, outras",
        },
        {
          value: "S84",
          label: "Impetigo",
        },
        {
          value: "S85",
          label: "Cisto pilonidal/fístula",
        },
        {
          value: "S86",
          label: "Dermatite seborreica",
        },
        {
          value: "S87",
          label: "Dermatite/eczema atópico",
        },
        {
          value: "S88",
          label: "Dermatite de contato/alérgica",
        },
        {
          value: "S89",
          label: "Dermatite das fraldas",
        },
        {
          value: "S90",
          label: "Pitiríase rosada",
        },
        {
          value: "S91",
          label: "Psoríase",
        },
        {
          value: "S92",
          label: "Doença das glândulas sudoríparas",
        },
        {
          value: "S93",
          label: "Cisto sebáceo",
        },
        {
          value: "S94",
          label: "Unha encravada",
        },
        {
          value: "S95",
          label: "Molusco contagioso",
        },
        {
          value: "S96",
          label: "Acne",
        },
        {
          value: "S97",
          label: "Úlcera crônica da pele",
        },
        {
          value: "S98",
          label: "Urticária",
        },
        {
          value: "S99",
          label: "Outras doenças da pele",
        },
      ],
    },
    {
      category: "ENDÓCRINO/METABÓLICO E NUTRICIONAL",
      subcategories: [
        {
          value: "T01",
          label: "Sede excessiva",
        },
        {
          value: "T02",
          label: "Apetite excessivo",
        },
        {
          value: "T03",
          label: "Perda de apetite",
        },
        {
          value: "T04",
          label: "Problemas alimentares de lactente/criança",
        },
        {
          value: "T05",
          label: "Problemas alimentares do adulto",
        },
        {
          value: "T07",
          label: "Aumento de peso",
        },
        {
          value: "T08",
          label: "Perda de peso",
        },
        {
          value: "T10",
          label: "Atraso do crescimento",
        },
        {
          value: "T11",
          label: "Desidratação",
        },
        {
          value: "T26",
          label: "Medo de câncer do sistema endócrino",
        },
        {
          value: "T27",
          label: "Medo de outra doença endócrina/metabólica",
        },
        {
          value: "T28",
          label: "Limitação funcional/incapacidade",
        },
        {
          value: "T29",
          label: "Sinais/sintomas endocrinológicos/metabólicos/nutricionais, outros",
        },
        {
          value: "T70",
          label: "Infecção endócrina",
        },
        {
          value: "T71",
          label: "Neoplasia maligna da tiroide",
        },
        {
          value: "T72",
          label: "Neoplasia benigna da tiroide",
        },
        {
          value: "T73",
          label: "Outra neoplasia endócrina NE",
        },
        {
          value: "T78",
          label: "Cisto do canal tiroglosso",
        },
        {
          value: "T80",
          label: "Malformação congênita endócrina/metabólica",
        },
        {
          value: "T81",
          label: "Bócio",
        },
        {
          value: "T82",
          label: "Obesidade",
        },
        {
          value: "T83",
          label: "Excesso de peso",
        },
        {
          value: "T85",
          label: "Hipertiroidismo/tireotoxicose",
        },
        {
          value: "T86",
          label: "Hipotiroidismo/mixedema",
        },
        {
          value: "T87",
          label: "Hipoglicemia",
        },
        {
          value: "T89",
          label: "Diabetes insulino-dependente",
        },
        {
          value: "T90",
          label: "Diabetes não insulino-dependente",
        },
        {
          value: "T91",
          label: "", // O rótulo para T91 está ausente, por favor, forneça a descrição correspondente
        },
        {
          value: "T92",
          label: "Gota",
        },
        {
          value: "T93",
          label: "Alteração no metabolismo dos lipídios",
        },
        {
          value: "T99",
          label: "Outras doenças endocrinológicas/metabólicas/nutricionais",
        },
      ],
    },
    {
      category: "URINÁRIO",
      subcategories: [
        {
          value: "U01",
          label: "Disúria/micção dolorosa",
        },
        {
          value: "U02",
          label: "Micção frequente/urgência urinária/polaciúria",
        },
        {
          value: "U04",
          label: "Incontinência urinária",
        },
        {
          value: "U05",
          label: "Outros problemas com a micção",
        },
        {
          value: "U06",
          label: "Hematúria",
        },
        {
          value: "U07",
          label: "Outros sinais/sintomas urinários",
        },
        {
          value: "U08",
          label: "Retenção urinária",
        },
        {
          value: "U13",
          label: "Sinais/sintomas da bexiga, outros",
        },
        {
          value: "U14",
          label: "Sinais/sintomas dos rins",
        },
        {
          value: "U26",
          label: "Medo de câncer no aparelho urinário",
        },
        {
          value: "U27",
          label: "Medo de outra doença urinária",
        },
        {
          value: "U28",
          label: "Limitação funcional/incapacidade",
        },
        {
          value: "U29",
          label: "Sinais/sintomas aparelho urinário, outros",
        },
        {
          value: "U70",
          label: "Pielonefrite",
        },
        {
          value: "U71",
          label: "Cistite/outra infecção urinária",
        },
        {
          value: "U72",
          label: "Uretrite",
        },
        {
          value: "U75",
          label: "Neoplasia maligna do rim",
        },
        {
          value: "U76",
          label: "Neoplasia benigna do rim",
        },
        {
          value: "U77",
          label: "Neoplasia maligna do aparelho urinário, outra",
        },
        {
          value: "U78",
          label: "Neoplasia benigna do aparelho urinário",
        },
        {
          value: "U79",
          label: "Neoplasia do aparelho urinário NE",
        },
        {
          value: "U80",
          label: "Lesões traumáticas do aparelho urinário",
        },
        {
          value: "U85",
          label: "Malformação congênita do aparelho urinário",
        },
        {
          value: "U88",
          label: "Glomerulonefrite/síndrome nefrótica",
        },
        {
          value: "U90",
          label: "Albuminúria/proteinúria ortostática",
        },
        {
          value: "U95",
          label: "Cálculo urinário",
        },
        {
          value: "U98",
          label: "Análise de urina anormal NE",
        },
        {
          value: "U99",
          label: "Outras doenças urinárias",
        },
      ],
    },
    {
      category: "GENITAL FEMININO",
      subcategories: [
        {
          value: "X01",
          label: "Dor genital",
        },
        {
          value: "X02",
          label: "Dores menstruais",
        },
        {
          value: "X03",
          label: "Dores intermenstruais",
        },
        {
          value: "X04",
          label: "Relação sexual dolorosa na mulher",
        },
        {
          value: "X05",
          label: "Menstruação escassa/ausente",
        },
        {
          value: "X06",
          label: "Menstruação excessiva",
        },
        {
          value: "X07",
          label: "Menstruação irregular/frequente",
        },
        {
          value: "X08",
          label: "Hemorragia intermenstrual",
        },
        {
          value: "X09",
          label: "Sinais/sintomas pré-menstruais",
        },
        {
          value: "X10",
          label: "Desejo de alterar a data menstruação",
        },
        {
          value: "X11",
          label: "Sinais/sintomas da menopausa/ climatério",
        },
        {
          value: "X12",
          label: "Hemorragia pós-menopausa",
        },
        {
          value: "X13",
          label: "Hemorragia pós-coital",
        },
        {
          value: "X14",
          label: "Secreção vaginal",
        },
        {
          value: "X15",
          label: "Sinais/sintomas da vagina",
        },
        {
          value: "X16",
          label: "Sinais/sintomas da vulva",
        },
        {
          value: "X17",
          label: "Sinais/sintomas da pélvis feminina",
        },
        {
          value: "X18",
          label: "Dor na mama feminina",
        },
        {
          value: "X19",
          label: "Tumor ou nódulo na mama feminina",
        },
        {
          value: "X20",
          label: "Sinais/sintomas do mamilo da mulher",
        },
        {
          value: "X21",
          label: "Sinais/sintomas da mama feminina, outros",
        },
        {
          value: "X22",
          label: "Preocupação com a aparência da mama feminina",
        },
        {
          value: "X23",
          label: "Medo de doença de transmissão sexual",
        },
        {
          value: "X24",
          label: "Medo de disfunção sexual",
        },
        {
          value: "X25",
          label: "Medo de câncer genital",
        },
        {
          value: "X26",
          label: "Medo de câncer na mama",
        },
        {
          value: "X27",
          label: "Medo de outra doença genital/mama",
        },
        {
          value: "X28",
          label: "Limitação funcional/incapacidade",
        },
        {
          value: "X29",
          label: "Sinais/sintomas do aparelho genital feminino, outra",
        },
        {
          value: "X70",
          label: "Gonorréia feminina",
        },
        {
          value: "X71",
          label: "Sífilis feminina",
        },
        {
          value: "X72",
          label: "Candidíase genital feminina",
        },
        {
          value: "X73",
          label: "Tricomoníase genital feminina",
        },
        {
          value: "X75",
          label: "Neoplasia maligna do colo",
        },
        {
          value: "X76",
          label: "Neoplasia maligna da mama feminina",
        },
        {
          value: "X77",
          label: "Neoplasia maligna genital feminina, outra",
        },
        {
          value: "X78",
          label: "Fibromioma uterino",
        },
        {
          value: "X79",
          label: "Neoplasia benigna da mama feminina",
        },
        {
          value: "X80",
          label: "Neoplasia benigna genital",
        },
        {
          value: "X81",
          label: "Neoplasia genital feminina, outra/NE",
        },
        {
          value: "X82",
          label: "Lesão traumática genital feminina",
        },
        {
          value: "X83",
          label: "Malformações congênitas genitais",
        },
        {
          value: "X84",
          label: "Vaginite/vulvite NE",
        },
        {
          value: "X85",
          label: "Doença do colo NE",
        },
        {
          value: "X86",
          label: "Esfregaço de Papanicolau/colpocitologia oncótica anormal",
        },
        {
          value: "X87",
          label: "Prolapso utero-vaginal",
        },
        {
          value: "X88",
          label: "Doença fibrocística da mama",
        },
        {
          value: "X89",
          label: "Síndrome da tensão pré-menstrual",
        },
        {
          value: "X90",
          label: "Herpes genital feminino",
        },
        {
          value: "X91",
          label: "Condiloma acuminado feminino",
        },
        {
          value: "X92",
          label: "Infecção por clamídia",
        },
        {
          value: "X99",
          label: "Doença genital feminina, outra",
        },
      ],
    },
    {
      category: "GENITAL MASCULINO",
      subcategories: [
        {
          value: "Y01",
          label: "Dor no pênis",
        },
        {
          value: "Y02",
          label: "Dor no escroto/testículos",
        },
        {
          value: "Y03",
          label: "Secreção uretral",
        },
        {
          value: "Y04",
          label: "Sinais/sintomas do pênis, outros",
        },
        {
          value: "Y05",
          label: "Sinais/sintomas do escroto/ testículos, outros",
        },
        {
          value: "Y06",
          label: "Sinais/sintomas da próstata",
        },
        {
          value: "Y07",
          label: "Impotência NE",
        },
        {
          value: "Y08",
          label: "Sinais/sintomas da função sexual masculina, outros",
        },
        {
          value: "Y10",
          label: "Infertilidade/subfertilidade masculina",
        },
        {
          value: "Y13",
          label: "Esterilização masculina",
        },
        {
          value: "Y14",
          label: "Planejamento familiar, outros",
        },
        {
          value: "Y16",
          label: "Sinais/sintomas da mama masculina",
        },
        {
          value: "Y24",
          label: "Medo de disfunção sexual masculina",
        },
        {
          value: "Y25",
          label: "Medo de doença sexualmente transmissível",
        },
        {
          value: "Y26",
          label: "Medo de câncer genital masculino",
        },
        {
          value: "Y27",
          label: "Medo de doença genital masculina, outra",
        },
        {
          value: "Y28",
          label: "Limitação funcional/incapacidade",
        },
        {
          value: "Y29",
          label: "Sinais/sintomas, outros",
        },
        {
          value: "Y70",
          label: "Gonorréia masculina",
        },
        {
          value: "Y71",
          label: "Sífilis masculina",
        },
        {
          value: "Y72",
          label: "Herpes genital",
        },
        {
          value: "Y73",
          label: "Prostatite/vesiculite seminal",
        },
        {
          value: "Y74",
          label: "Orquite/epididimite",
        },
        {
          value: "Y75",
          label: "Balanite/Balanopostite",
        },
        {
          value: "Y76",
          label: "Condiloma acuminado",
        },
        {
          value: "Y77",
          label: "Neoplasia maligna da próstata",
        },
        {
          value: "Y78",
          label: "Neoplasia maligna genital masculina, outra",
        },
        {
          value: "Y79",
          label: "Neoplasia benigna genital masculina NE",
        },
        {
          value: "Y80",
          label: "Traumatismo genital masculino, outro",
        },
        {
          value: "Y81",
          label: "Fimose/prepúcio redundante",
        },
        {
          value: "Y82",
          label: "Hipospádias",
        },
        {
          value: "Y83",
          label: "Testículo não descido/ Criptorquidia/ Testículo ectópico",
        },
        {
          value: "Y84",
          label: "Malformação genital congênita masculina, outra",
        },
        {
          value: "Y85",
          label: "Hipertrofia prostática benigna",
        },
        {
          value: "Y86",
          label: "Hidrocele",
        },
        {
          value: "Y99",
          label: "Doença genital masculina, outra",
        },
      ],
    },
    {
      category: "PROBLEMAS SOCIAIS",
      subcategories: [
        {
          value: "Z01",
          label: "Pobreza/problemas econômicos",
        },
        {
          value: "Z02",
          label: "Problemas relacionados a água/alimentação",
        },
        {
          value: "Z03",
          label: "Problemas de habitação/vizinhança",
        },
        {
          value: "Z04",
          label: "Problema socio-cultural",
        },
        {
          value: "Z05",
          label: "Problemas com condições de trabalho",
        },
        {
          value: "Z06",
          label: "Problemas de desemprego",
        },
        {
          value: "Z07",
          label: "Problemas relacionados com educação",
        },
        {
          value: "Z08",
          label: "Problema relacionado com sistema de segurança social",
        },
        {
          value: "Z09",
          label: "Problema de ordem legal",
        },
        {
          value: "Z10",
          label: "Problema relacionado com sistema de saúde",
        },
        {
          value: "Z11",
          label: "Problema relacionado com estar doente",
        },
        {
          value: "Z12",
          label: "Problema de relacionamento com parceiro/conjugal",
        },
        {
          value: "Z13",
          label: "Problema comportamental do parceiro/companheiro",
        },
        {
          value: "Z14",
          label: "Problema por doença do parceiro/companheiro",
        },
        {
          value: "Z15",
          label: "Perda ou falecimento do parceiro/companheiro",
        },
        {
          value: "Z16",
          label: "Problema de relacionamento com criança",
        },
        {
          value: "Z18",
          label: "Problema com criança doente",
        },
        {
          value: "Z19",
          label: "Perda ou falecimento de criança",
        },
        {
          value: "Z20",
          label: "Problema de relacionamento com familiares",
        },
        {
          value: "Z21",
          label: "Problema comportamental de familiar",
        },
        {
          value: "Z22",
          label: "Problema por doença familiar",
        },
        {
          value: "Z23",
          label: "Perda/falecimento de familiar",
        },
        {
          value: "Z24",
          label: "Problema de relacionamento com amigos",
        },
        {
          value: "Z25",
          label: "Ato ou acontecimento violento",
        },
        {
          value: "Z27",
          label: "Medo de problema social",
        },
        {
          value: "Z28",
          label: "Limitação funcional/incapacidade",
        },
        {
          value: "Z29",
          label: "Problema social NE",
        },
      ],
    },
  ];

  const handleOptionSelect = option => {
    if (!selectedOptions.includes(option.value)) {
      setSelectedOptions([...selectedOptions, option.value]);
      handleChange([...selectedOptions, option.value]);
    } else {
      setSelectedOptions(selectedOptions.filter(item => item !== option.value));
      handleChange(selectedOptions.filter(item => item !== option.value));
    }    
  };

  const toggleDropdown = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const toggleCat = event => {
    console.log(isCatOpen)
    if (isCatOpen) {
      if(isCatOpen == event.target.className){
        setIsCatOpen(!isCatOpen);
      }else{
        setIsCatOpen(event.target.className);
      }
    } else {
      setIsCatOpen(event.target.className);
    }

    setIsCatClose(true);
  };

  const closeCats = () => {
    setIsCatOpen(!isCatOpen);
  };

  const filteredOptions = options.filter(option => option.subcategories.some(subcat => subcat.label.toLowerCase().includes(searchTerm.toLowerCase())));

  return (
    <div id="catSearch" className="select-container">
      <input
        className="form-input input-login select-treatment"
        type="text"
        placeholder="Pesquise pelo motivo do tratamento"
        value={searchTerm}
        onChange={e => {
          setIsCatOpen("all");
          setSearchTerm(e.target.value);
        }}
        onClick={() => {
          toggleDropdown();
          setIsSelectOpen(true);
        }}
      />
      <div className={`conteiner-options ${isSelectOpen ? "open" : ""}`}>
      <p style={{color:"white",fontWeight:"bold",textAlign:"center",fontSize:"18pp", paddingTop:"10px"}}>SELECIONE AS OPÇÕES ABAIXO</p>
        {filteredOptions.map((option, index) => (
          <div key={index} className="option-group">
            <h3 className={"options" + index} onClick={toggleCat}>
              {option.category}
            </h3>

            <div className={"option" + index} style={{ display: isCatOpen === "options" + index || isCatOpen === "all" ? "block" : "none" }}>
              {option.subcategories
                .filter(subcat => subcat.label.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((subcat, idx) => (
                  <label key={idx} className="option-label">
                    <input
                      type="checkbox"
                      className="option-checkbox"
                      onChange={() => {
                        handleOptionSelect(subcat);
                      }}
                      checked={selectedOptions.includes(subcat.value)}
                    />
                    {subcat.label}
                  </label>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


export default SelectComponent;
