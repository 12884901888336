import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import ReactDOM from 'react-dom';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

const options = [
    "ABSTINÊNCIA ALCOÓLICA", "AFASIA PROGRESSIVA PRIMÁRIA", "AGRESSIVIDADE", "ALCOOLISMO", "ALERGIA", "ALTERAÇÕES COMPORTAMENTAIS", "ALTERAÇÕES HEPÁTICAS", "ALTERAÇÕES OSTEODISTROFICAS NAS VÉRTEBRAS", "ALZHEIMER", "ANOMALIA CEREBRAL",
    "ANOREXIA", "ANSIEDADE", "APILEPSIA IDIOPÁTICA", "ARRITMIA CARDÍACA", "ARTRALGIA", "ARTRITE", "ARTRITE INDIFERENCIADA", "ARTRITE PSORIÁSICA", "ARTRITE REUMATOIDE", "ARTROSE", "ARTROSE CRÔNICA", "ASMA", "ASPERGER", "ATAXIA",
    "ATOPIA", "ATRASO COORDENAÇÃO MOTORA", "ATRASO NA FALA", "ATRITE PSORIASICA", "AUTISMO", "AUTOMUTILAÇÃO", "AVC", "AVC HEMORRÁGICO", "AVE", "BICO DE PAPAGAIO", "BIRRA", "BORDERLINE", "BRONQUITE", "BRUXISMO", "BURSITE",
    "BUSCA DE EQUILÍBRIO EMOCIONAL", "CÂNCER", "CÂNCER BENIGNO", "CÂNCER CEREBRAL", "CÂNCER COM METÁSTASE", "CÂNCER DE COLON", "CÂNCER DE ENDOMÉTRIO E OVÁRIO", "CÂNCER DE ESTÔMAGO", "CÂNCER DE FÍGADO", "CÂNCER DE MAMA",
    "CÂNCER DE MANDÍBULA", "CÂNCER DE PULMÃO", "CÂNCER NA BEXIGA", "CÂNCER NO INTESTINO", "CÂNCER TERMINAL - MELANOMA", "CARCINOMA", "CARCINOMA DE BEXIGA", "CARCINOMA DE SACO ANAL", "CARCINOMA INVASIVO DE MAMA",
    "CHORO", "CICLO CIRCADIANO ALTERADO", "CID 10: 31.2", "CID 10: 6808", "CID 10: 90", "CID 10: 91", "CID 10: 91.3", "CID 10: C50", "CID 10: CID 10: F33.2", "CID 10: F32.2", "CID 10: F33.1: DEPRESSÃO BIPOLAR",
    "CID 10: F33.2", "CID 10: F41.1", "CID 10: F43.2 - TEPT", "CID 10: F60.3", "CID 10: F72", "CID 10: F72.1", "CID 10: F720", "CID 10: G11.9", "CID 10: G20", "CID 10: G30.1", "CID 10: M51.1 - TRANSTORNOS DE DISCOS LOMBARES E DE OUTROS DISCOS INTERVERTEBRAIS COM RADICULOPATIA",
    "CINOMOSE", "CINOMOSE VACINAL", "CIRROSE HEPÁTICA", "CONDROPATIA FEMOROACETABULAR", "CONDROPATIA PATELAR", "CONSTIPAÇÃO INTESTINAL PERSISTENTE", "COXOARTROSE", "CROHN", "DAD", "DBE", "DEBILIDADE FÍSICA POR IDADE",
    "DEFICIÊNCIA DE CARNITINA PALMITOIL TRANSFERASE TIPO 2", "DEFICIÊNCIA INTELECTUAL", "DEFICIÊNCIA MENTAL CONGÊNITA", "DÉFICIT COGNITIVO", "DÉFICIT DE ATENÇÃO", "DEGENERAÇÃO CEREBRAL", "DEMÊNCIA", "DEMÊNCIA DE PICK",
    "DEMÊNCIA POR CORPOS DE LEVY", "DEMÊNCIA SENIL", "DEPENDÊNCIA QUÍMICA", "DEPRESSÃO", "DERMATITE", "DERMATITE ATÓPICA", "DESMAME DE BENZODIAZEPÍNICO", "DIABETES", "DIFICULDADE PARA CONCENTRAR", "DIID",
    "DISCENESIA PAROXÍSTICA", "DISCOPATIA", "DISCOPATIA LOMBAR", "DISFAGIA", "DISFUNÇÃO COGNITIVA", "DISFUNÇÃO TEMPOROMANDIBULAR", "DISLEXIA", "DISPLASIA COXA FEMURAL", "DISTÚRBIO COGNITIVO", "DISTÚRBIO COMPORTAMENTAL",
    "DISTÚRBIO DO SONO", "DISTÚRBIO MENTAL", "DISTÚRBIO NEUROLÓGICO", "DOENÇA DE HUNTINGTON", "DOENÇA DEGENERATIVA", "DOENÇA DEGENERATIVA ARTICULAR", "DOENÇA DOS DISCOS INTERVERTEBRAIS", "DOENÇA INFLAMATÓRIA INTESTINAL",
    "DOENÇA RENAL CRÔNICA", "DOR ARTICULAR", "DOR CRÔNICA", "DOR MIOFASCIAL", "DOR NEUROLÓGICA", "DOR OROFACIAL", "DOR REUMÁTICA", "DOR NAS ARTICULAÇÕES", "DTM", "EFEITOS COLATERAIS", "ELA BULBAR - ESCLEROSE LATERAL AMIOTRÓFICA BULBAR",
    "ENDOMETRIOSE", "ENXAQUECA", "EPILEPSIA", "EPILEPSIA DE DIFÍCIL CONTROLE", "EPILEPSIA FOCAL", "EPILEPSIA IDIOPÁTICA", "EPILEPSIA LOBO TEMPORAL", "EPILEPSIA REFRATÁRIA", "ESCLERODERMIA SISTÊMICA", "ESCLEROSE MESIAL TEMPORAL",
    "ESCLEROSE MÚLTIPLA", "ESCLEROSE SISTÊMICA", "ESPASMOS MUSCULARES", "ESPONDILITE ANQUILOSANTE", "ESPONDILOARTRITE", "ESQUECIMENTO", "ESQUIZOFRENIA PARANOIDE", "ESTENOSE CERVICAL", "ESTOMATITE", "FALTA DE APETITE",
    "FALTA DE EQUILÍBRIO", "FECALOMA", "FELV", "FIBROMIALGIA", "FIBROSARCOMA", "FIV", "FUSÃO CONGÊNITA DA CERVICAL", "G-35", "GANHO DE PESO", "GENGIVO ESTOMATITE", "GINECOLÓGICO", "GLAUCOMA", "GONARTROSE", "HACHEM",
    "ANGIOSSARCOMA", "HEPATOPATIA CRÔNICA", "HÉRNIA", "HÉRNIA DE DISCO", "HERPES ZOSTER", "HIPERATIVIDADE CEREBRAL", "HIPERESTESIA", "HIPERPLASIA PROSTÁTICA", "HIPERTENSÃO", "HIPERTIREOIDISMO", "HIPOTIREOIDISMO DE HASHIMOTO",
    "HIV", "INFLAMAÇÃO INTESTINAL", "INSÔNIA", "INSUFICIÊNCIA RENAL", "IRI", "IRRITABILIDADE", "LABIRINTITE", "LAMBEDURA COMPULSIVA", "LAMBEDURA PSICOGÊNICA", "LEISHMANIOSE", "LESÃO NA COLUNA", "LEUCEMIA", "LIBIDO", "LINFOMA",
    "LINFOMA DE HODGKIN", "LÚPUS", "LÚPUS ERITEMATOSO SISTÊMICO", "LUXAÇÃO", "MÁ FORMAÇÃO DO CEREBELO", "MAL DE PARKINSON", "MASTOCITOMA CUTÂNEO", "MEDO", "MEDO DE SEPARAÇÃO", "MELANOMA CUTÂNEO", "MEMÓRIA", "MENOPAUSA",
    "METÁSTASE", "METÁSTASE ÓSSEA", "MICROCEFALIA", "MIGRAINEA CRÔNICA", "NEOPLASIA", "NEURALGIA DO TRIGÊMEO", "NEUROLÓGICO", "NEUROPATIA", "NÓDULO NO BAÇO", "NÓDULO NO PEITO", "OBESIDADE", "OSTEOARTRITE NODAL",
    "OSTEONECROSE", "OSTEONECROSE FEMOROACETABULAR", "OSTEOPATIA", "OSTEOPOROSE AVANÇADA", "PANCREATITE", "PÂNICO", "PARALISIA CEREBRAL", "PARALISIA SUPRANUCLEAR PROGRESSIVA", "PERDA DE APETITE", "PLAQUETAS BAIXAS",
    "POLIARTRITE", "POLINEUROPATIA PERIFÉRICA GRAVE DE FIBRAS FINAS", "PRISÃO DE VENTRE", "PSORÍASE", "REUMATISMOS", "SARCOMA", "SARNA DEMODECICA", "SERINGOMIELIA", "SÍNDROME CHIARI LIKE", "SÍNDROME COGNITIVA SENIL",
    "SÍNDROME DA CAUDA EQUINA", "SÍNDROME DA DISFUNÇÃO COGNITIVA", "SÍNDROME DA IMUNODEFICIÊNCIA VIRAL FELINA", "SÍNDROME DA PICA", "SÍNDROME DE CUSHING", "SÍNDROME DE DELEÇÃO", "SÍNDROME DE EHLERS DANLOS",
    "SÍNDROME DE MOWAT WILSON", "SÍNDROME DE SJOGREN", "SÍNDROME DE TOURETTE", "SÍNDROME DE WILHANS", "SÍNDROME DO ABANDONO", "SÍNDROME DO INTESTINO IRRITÁVEL", "SÍNDROME DO Q14", "SÍNDROME DO TÚNEL DO CARPO", "SÍNDROME MIOFASCIAL",
    "SÍNDROME MIOFASCIAL CERVICAL", "SÍNDROME PÓS COVID 19", "SÍNDROME PÓS LAMINECTOMIA", "SINDROME DO PÂNICO", "SJOGREN", "STRESS", "TAG", "TDAH", "TDH", "TDM", "TOC", "TPM", "TRANSTORNO BIPOLAR", "TRANSTORNO BIPOLAR MANÍACO",
    "TRANSTORNO DE ADAPTAÇÃO", "TRANSTORNO DE ESPECTRO AUTISTA", "TRANSTORNO DE HUMOR", "TRANSTORNO DISFÓRICO PRÉ-MENSTRUAL", "TRANSTORNO DO SONO", "TRANSTORNO MENTAL ORGÂNICO", "TRANSTORNOS OBSESSIVOS COMPULSIVOS",
    "TRASTORNO DE PÂNICO", "TRAUMA RAQUIMEDULAR", "TREMOR ESSENCIAL", "TRICOTILOMANIA", "TRISTEZA", "TUMOR", "TUMORES DE PELE", "TUSS", "VÍCIO MACONHA", "OUTRO"
];


const ReasonTreatment = ({ onChange, value, ...otherProps }) => {
    const [selected, setSelected] = useState([]);

    const handleSelectionChange = (newSelected) => {
        setSelected(newSelected);
        if (onChange) {
            onChange(newSelected);
        }
    };


    return (
        <Typeahead
            style={{ height: "50px" }}
            name="reason_treatment"
            id="reason_treatment"
            onChange={handleSelectionChange}
            options={options}
            placeholder="Pesquise por uma opção"
            selected={selected}
        />
    );
};

export default ReasonTreatment;